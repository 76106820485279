import React, { useState, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Title from "../../common/Title"
import InputField from "../../common/InputField"
import { Button } from "../../common/Button"
import Loader from "../../common/Loader"
import SelectField from "../../common/SelectField"
import Message from "../../common/Message"
import OrderHistoryModal from "../../common/OrderHistoryModal"
import { getFormatedDate } from "../../../utils/FormatedDate"
import ChangeStationModal from "./ChangeStation/changeStationModal.js"
import TransferOrderModal from "./TransferOrder/transferOrderModal.js"
import NotificationModal from "../../common/NotificationModal"
import OrderDelayModal from "../../common/OrderDelayModal"
import YesNoModal from "../../common/YesNoModal"
import Select from "react-select"
import {
  onStartLoading,
  onUpdateOrder,
  setSelectedOrder,
  fetchOrderStatuses,
  closeMessage,
  getOrderDetails,
  onGetStations,
  fetchAllStationData,
  onUpdateOrderStation,
  clearOrderStationUpdate,
  updateOrderStatusAndExtentionDate,
  sendReadyInLockerSMS,
  clearSendReadyInLockerSMS,
  onTransferOrder,
  clearTransferOrder,
} from "../../../actions"

import "./style.scss"

const EditOrderScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Redux state
  const {
    authenticated,
    isLoading,
    showMessage,
    isError,
    order,
    ordersStatusesArr,
    orderDetails,
    userData,
    stationsItemSelectList,
    stationsAll,
    orderStationUpdatedSuccess,
    orderStationUpdatedFail,
    orderTypeGroup,
    sendReadyInLockerSMSSuccess,
    sendReadyInLockerSMSFail,
    transferOrderSuccess,
    transferOrderFail,
  } = useSelector((state) => ({
    authenticated: state.users.authenticated,
    isLoading: state.orders.isLoading,
    showMessage: state.orders.showMessage,
    isError: state.orders.isError,
    order: state.orders.order,
    ordersStatusesArr: state.orderStatuses.ordersStatusesArr,
    orderDetails: state.orders.orderDetails,
    userData: state.users.userData,
    stationsItemSelectList: state.station.stationsItemSelectList,
    stationsAll: state.station.stationsAll,
    orderStationUpdatedSuccess: state.orders.orderStationUpdatedSuccess,
    orderStationUpdatedFail: state.orders.orderStationUpdatedFail,
    orderStatusUpdateSuccess: state.orders.orderStatusUpdateSuccess,
    orderStatusUpdateFail: state.orders.orderStatusUpdateFail,
    orderTypeGroup: state.users.userData?.orderTypeGroup,
    sendReadyInLockerSMSSuccess: state.orders.sendReadyInLockerSMSSuccess,
    sendReadyInLockerSMSFail: state.orders.sendReadyInLockerSMSFail,
    transferOrderSuccess: state.orders.transferOrderSuccess,
    transferOrderFail: state.orders.transferOrderFail,
  }))

  const [selectedOrder, setSelectedOrder] = useState(order)
  const [isShowHistory, setIsShowHistory] = useState(false)
  const [isShowStations, setIsShowStations] = useState(false)
  const [showTransferOrderModal, setShowTransferOrderModal] = useState(false)
  const [showOrderDelayModal, setShowOrderDelayModal] = useState(false)
  const [showInLockerSmsConfirmModal, setShowInLockerSmsConfirmModal] =
    useState(false)
  const [selectedOrderStatus, setSelectedOrderStatus] = useState(
    order?.orderStatus
      ? {
          label: ordersStatusesArr[order.orderStatus],
          value: order.orderStatus,
        }
      : null
  )

  useEffect(() => {
    if (!authenticated) {
      navigate("/")
      return
    }
    if (!order) {
      navigate("/OrdersSearchScreen")
      return
    }

    dispatch(getOrderDetails(order?.id))
    dispatch(fetchOrderStatuses())
  }, [authenticated, order, navigate, dispatch])

  useEffect(() => {
    if (stationsAll?.length > 0 && stationsItemSelectList.length == 0) {
      dispatch(fetchAllStationData(stationsAll))
    }
  }, [stationsAll, stationsItemSelectList, dispatch])

  const isStatusValid = (currStatus, optionIdx) => {
    if (
      (currStatus == 3 && optionIdx != 6) ||
      currStatus == 12 ||
      currStatus == 6 ||
      currStatus == 5 ||
      (currStatus == 18 && optionIdx != 6) ||
      (currStatus == 19 && optionIdx != 6) ||
      (currStatus == 4 && optionIdx != 13 && optionIdx != 5) ||
      (currStatus == 13 && optionIdx != 12 && optionIdx != 5) ||
      (currStatus == 13 && optionIdx != 5)
    ) {
      return false
    }
    return true
  }

  const ordersStatusesList = useMemo(() => {
    const currentStatus = order?.orderStatus ?? 0
    return [
      {
        value: 4,
        label: "מוכן בלוקר",
        isDisabled: !isStatusValid(currentStatus, 4),
      },
      {
        value: 5,
        label: "נאסף על ידי לקוח",
        isDisabled: !isStatusValid(currentStatus, 5),
      },
      {
        value: 6,
        label: "מבוטל",
        isDisabled: !isStatusValid(currentStatus, 6),
      },
      {
        value: 12,
        label: "הוחזר לשולח",
        isDisabled: !isStatusValid(currentStatus, 12),
      },
      {
        value: 13,
        label: "סומן לחזרה לשולח",
        isDisabled: !isStatusValid(currentStatus, 13),
      },
    ]
  }, [order?.orderStatus])

  const onClickUpdateOrder = () => {
    dispatch(onStartLoading())
    const updatedOrder = { ...order, orderStatus: selectedOrderStatus.value }
    dispatch(onUpdateOrder(updatedOrder))
  }

  const updateStationHandler = (stationNumber) => {
    dispatch(onUpdateOrderStation(order.id, stationNumber))
    setIsShowStations(false)
  }

  const transferOrderHandler = (stationId) => {
    dispatch(onTransferOrder(order.id, stationId))
    setShowTransferOrderModal(false)
  }

  const updateInLockerDate = (selectedDate) => {
    const dateWithTime = new Date(selectedDate)
    dateWithTime.setHours(23)
    dateWithTime.setMinutes(0)
    const unixTimestamp = Math.floor(dateWithTime.getTime() / 1000)
    dispatch(
      updateOrderStatusAndExtentionDate(selectedOrder?.id, 4, unixTimestamp)
    )
  }

  const inLockerSmsConfirmed = () => {
    dispatch(sendReadyInLockerSMS(selectedOrder?.id))
    setShowInLockerSmsConfirmModal(false)
  }

  const isShowLockerData = () => {
    if (
      order.orderStatus == 0 ||
      order.orderStatus == 4 ||
      order.orderStatus == 13 ||
      order.orderStatus == 18
    ) {
      return (
        <>
          <InputField
            show={true}
            value={order.currentLocation?.replace(/\D/g, "")}
            labelText="מספר לוקר"
            disabled
          />
          <InputField
            show={true}
            value={
              order.deliveryLoginCode
                ? order.deliveryLoginCode
                : orderDetails?.deliveryLoginCode
            }
            labelText="לוקר קוד"
            disabled
          />
        </>
      )
    }
    return null
  }

  if (isLoading || order === null) {
    return (
      <div>
        <Title text="עדכון הזמנה" />
        <Loader show={isLoading} />
        <Message
          show={showMessage}
          isError={isError}
          successText="ההזמנה עודכנה בהצלחה"
          errorText="ההזמנה לא עודכנה"
          onClick={() => {
            dispatch(closeMessage())
            navigate("/OrdersSearchScreen")
          }}
        />
      </div>
    )
  }

  return (
    <div className="update-order">
      <Title text="עדכון הזמנה" />
      <div className="btn-header-holder">
        <div onClick={() => setIsShowHistory(true)}>היסטוריה של הזמנה</div>
        {orderTypeGroup == "2" && order.orderStatus == 3 && (
          <div
            onClick={() => {
              dispatch(onGetStations())
              setIsShowStations(true)
            }}
          >
            שינוי תחנה
          </div>
        )}
        {orderTypeGroup == "1" &&
          (order.orderStatus == 4 || order.orderStatus == 13) && (
            <div
              onClick={() => {
                dispatch(onGetStations())
                setShowTransferOrderModal(true)
              }}
            >
              העברה לתחנה אחרת
            </div>
          )}
        {["3", "4", "13"].includes(order?.orderStatus) && (
          <div onClick={() => setShowOrderDelayModal(true)}>
            הארכת זמן חבילה בתא
          </div>
        )}
        {order?.orderStatus == 4 && (
          <div onClick={() => setShowInLockerSmsConfirmModal(true)}>
            שלח SMS "מוכן בלוקר"
          </div>
        )}
      </div>

      {isShowStations && stationsItemSelectList.length > 0 && (
        <ChangeStationModal
          stations={stationsItemSelectList}
          currentStationNumber={orderDetails?.stationNumber}
          onSaveClick={updateStationHandler}
          onCloseClick={() => setIsShowStations(false)}
        />
      )}

      {showTransferOrderModal && stationsItemSelectList.length > 0 && (
        <TransferOrderModal
          stations={stationsItemSelectList}
          currentStationNumber={orderDetails?.stationNumber}
          onSaveClick={transferOrderHandler}
          onCloseClick={() => setShowTransferOrderModal(false)}
        />
      )}

      <InputField
        show={true}
        value={order.orderNumber}
        placeholder="מספר הזמנה"
        disabled
      />
      <InputField
        show={true}
        value={order.packageNumber}
        placeholder="מספר חבילה"
        disabled
      />
      <InputField
        show={true}
        value={order.mobilePhone}
        placeholder="מספר טלפון"
        disabled
      />
      <InputField
        show={true}
        value={order.firstName}
        placeholder="שם פרטי"
        disabled
      />
      <InputField
        show={true}
        value={order.lastName}
        placeholder="שם משפחה"
        disabled
      />

      {isShowLockerData()}

      <div className="select-status">
        <div className="select-wrapper">
          <Select
            classNamePrefix=""
            value={selectedOrderStatus}
            placeholder={"סטטוס הזמנה"}
            isClearable={true}
            options={ordersStatusesList}
            onChange={(evt) => setSelectedOrderStatus(evt)}
          />
        </div>
      </div>

      <div className="buttons-wrapper">
        <Button onClick={onClickUpdateOrder}>עדכן הזמנה</Button>
        <Button extraClass="back-btn" onClick={() => navigate(-1)}>
          חזור
        </Button>
      </div>

      <OrderHistoryModal
        isShow={isShowHistory}
        order={orderDetails}
        setViewHistory={() => setIsShowHistory(false)}
        statuses={ordersStatusesArr}
        getFormatedDate={getFormatedDate}
      />

      <OrderDelayModal
        isOpen={showOrderDelayModal}
        isClose={() => setShowOrderDelayModal(false)}
        submitDate={updateInLockerDate}
        inLockerDate={selectedOrder?.inLockerExtentionDate}
      />

      <YesNoModal
        show={showInLockerSmsConfirmModal}
        title={"שלח SMS"}
        text={"?האם אתה בטוח שברצונך לשלוח הודעה ללקוח"}
        onYesClick={inLockerSmsConfirmed}
        onNoClick={() => setShowInLockerSmsConfirmModal(false)}
      />

      <NotificationModal
        show={sendReadyInLockerSMSSuccess}
        title={"SMS ללקוח"}
        text={"SMS נשלח בהצלחה"}
        onOkClick={() => dispatch(clearSendReadyInLockerSMS())}
      />

      <NotificationModal
        show={sendReadyInLockerSMSFail}
        title={"SMS ללקוח"}
        text={"שליחת SMS נכשל"}
        onOkClick={() => dispatch(clearSendReadyInLockerSMS())}
      />

      <NotificationModal
        show={orderStationUpdatedSuccess}
        title={"עדכון עמדה"}
        text={"עמדה עודכנה בהצלחה"}
        onOkClick={() => dispatch(clearOrderStationUpdate())}
      />
      <NotificationModal
        extraClass="error"
        show={orderStationUpdatedFail}
        title={"עדכון עמדה"}
        text={"עדכון עמדה נכשל"}
        onOkClick={() => clearOrderStationUpdate()}
      />

      <NotificationModal
        extraClass="success"
        show={transferOrderSuccess}
        text={"בקשה להעברת הזמנה לעמדה אחרת התקבלה בהצלחה"}
        onOkClick={() => dispatch(clearTransferOrder())}
      />
      <NotificationModal
        extraClass="error"
        show={transferOrderFail}
        text={" .שגיא, בקשה לא התקבלה "}
        onOkClick={() => dispatch(clearTransferOrder())}
      />

      <Message
        show={showMessage}
        isError={isError}
        successText="ההזמנה עודכנה בהצלחה"
        errorText="ההזמנה לא עודכנה"
        onClick={() => {
          dispatch(closeMessage())
          navigate("/OrdersSearchScreen")
        }}
      />
    </div>
  )
}

export default EditOrderScreen
